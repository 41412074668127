import React from 'react';
import './ServiceDetails.css';

const ServiceDetails = ({ title, description, title2, description2, image }) => {
    // Utility function to split descriptions into paragraphs
    const formatDescription = (description) =>
        description ? description.split('\n').filter(paragraph => paragraph.trim() !== '') : [];

    const paragraphs1 = formatDescription(description);
    const paragraphs2 = formatDescription(description2);

    return (
        <div className="servicedetails-outer-container">
            <div className="servicedetails-container">
                <div className="servicedetails-image-container">
                    <img src={image} alt={title} className="servicedetails-image" />
                </div>
                <div className="servicedetails-content">
                    {/* Render the first title and description */}
                    <h2 className="servicedetails-title">{title}</h2>
                    {paragraphs1.map((paragraph, index) => (
                        <p key={`desc1-${index}`} className="servicedetails-description">{paragraph}</p>
                    ))}

                    {/* Conditionally render the second title and description */}
                    {title2 && (
                        <>
                            <h2 className="servicedetails-title">{title2}</h2>
                            {paragraphs2.map((paragraph, index) => (
                                <p key={`desc2-${index}`} className="servicedetails-description">{paragraph}</p>
                            ))}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ServiceDetails;
