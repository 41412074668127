import React, { useState, useEffect } from 'react';
import { Button } from './homepage/Button';
import { Link as ScrollLink, scroller } from 'react-scroll';
import { useLocation, useNavigate } from 'react-router-dom';
import logoimg from '../images/slatenobackground.png';
import './Navbar.css';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', showButton);
    return () => window.removeEventListener('resize', showButton);
  }, []);

  const isHomePage = location.pathname === '/';

  const handleContactUsClick = (containerName) => {
    closeMobileMenu();
    if (!isHomePage || containerName === 'footer-container') {
      navigate('/');
      setTimeout(() => {
        scroller.scrollTo(containerName, {
          smooth: true,
          offset: -70,
          duration: 500,
        });
      }, 500);
    }
  };

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <div className='navbar-logo'>
            <img src={logoimg} alt='Slate Logo' className='navbar-logo-img' onClick={handleContactUsClick}/>
          </div>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <ScrollLink
                className='nav-links'
                onClick={() => handleContactUsClick('hero-container')}
                smooth={true}
                offset={-70}
                duration={500}
                to='hero-container'
              >
                Home
              </ScrollLink>
            </li>
            <li className='nav-item'>
              <ScrollLink
                className='nav-links'
                onClick={() => handleContactUsClick('services-container')}
                smooth={true}
                offset={-70}
                duration={500}
                to='services-container'
              >
                About Us
              </ScrollLink>
            </li>
            <li className='nav-item'>
              <ScrollLink
                className='nav-links'
                onClick={() => handleContactUsClick('cards')}
                smooth={true}
                offset={-70}
                duration={500}
                to='cards'
              >
                The Team
              </ScrollLink>
            </li>
            <li className='nav-item'>
              <ScrollLink
                className='nav-links'
                onClick={() => handleContactUsClick('TaxSavingsCarousel-section')}
                smooth={true}
                offset={-70}
                duration={600}
                to='TaxSavingsCarousel-section'
              >
                Services
              </ScrollLink>
            </li>

            <li>
              <button className='nav-links-mobile' onClick={() => handleContactUsClick('footer-container')}>
                Contact Us
              </button>
            </li>
          </ul>
          {button && (
            <Button buttonStyle='btn--outline' onClick={() => handleContactUsClick('footer-container')}>
              Contact Us
            </Button>
          )}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
