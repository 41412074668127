import React from 'react';
import '../../App.css';
import './HeroSection.css';
import video from '../../videos/dallas.mp4';
import logoimg from '../../images/slatenobackground.png';
import posterImg from '../../images/dallasposter.jpg';

function HeroSection() {
  return (
    <div className='hero-container' id='hero-container'>
      <video 
        poster={posterImg}
        autoPlay 
        loop 
        controls={false} 
        playsInline 
        muted 
        src={video} 
        type="video/mp4" 
        loading="eager"
      />
      <div className='logo-container'>
        <img src={logoimg} className='logo-container-img' alt="Logo" />
      </div>
    </div>
  );
}

export default HeroSection;
