import React, { useEffect } from 'react';
import CardItem from './CardItem'
import './Cards.css'
import charlie from '../../images/headshots/charlie.webp'
import brad from '../../images/headshots/brad.webp'
import beverly from '../../images/headshots/beverly.webp'
import will from '../../images/headshots/will.webp'
import max from '../../images/headshots/max.webp'
import { useNavigate } from 'react-router-dom';

function Cards() {

  const navigate = useNavigate();

  useEffect(() => {
    // Scroll to the top of the page when this component is mounted
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='cards' id='cards'>
      <h1 className='card__header'>Meet Our Team</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className='cards__items'>
            <CardItem
              className='cards__self'
              src={will}
              text='Will Beazley, MAI'
              label='Principal'
              phone='214-214-2222'
              email='Will.Beazley@slatepts.com'
              onClick={() => {
                navigate('/will-beazley-bio');
                window.scrollTo(0, 0); // Scroll to top when navigating
              }}
              route='/will-beazley-bio'
            />
            <CardItem
              src={charlie}
              text='Charlie Young, CMI'
              label='Principal'
              phone='214-214-2222'
              email='Charlie.Young@slatepts.com'
              onClick={() => {
                navigate('/charlie-young-bio');
                window.scrollTo(0, 0);
              }}
              route='/charlie-young-bio'
            />
            <CardItem
              src={brad}
              text='Brad Matheidas'
              label='Principal'
              phone='214-214-2222'
              email='Brad.Matheidas@slatepts.com'
              onClick={() => {
                navigate('/brad-matheidas-bio');
                window.scrollTo(0, 0);
              }}
              route = '/brad-matheidas-bio'
            />
            <CardItem
              src={max}
              text='Max Row, MAI'
              label='Director of Sales'
              phone='214-214-2222'
              email='Max.Row@slatepts.com'
              onClick={() => {
                navigate('/max-row-bio');
                window.scrollTo(0, 0);
              }}
              route='/max-row-bio'
            />
            <CardItem
              src={beverly}
              text='Beverly Kirkpatrick'
              label='Director of Tax Admin'
              phone='214-214-2222'
              email='Beverly.Kirkpatrick@slatepts.com'
              onClick={() => {
                navigate('/beverly-kirkpatrick-bio');
                window.scrollTo(0, 0);
              }}
              route='/beverly-kirkpatrick-bio'
            />
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Cards;
