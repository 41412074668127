import React from 'react';
import './AboutUsV2.css';

const Services = () => {
  return (
    <div className="services-container">
      <h2 className="services-title">Driving Principles</h2>
      <div className="service-item">
        <div className="service-title">Expertise</div>
        <div className="service-description">
          You wouldn't hire a general practitioner to perform surgery. Slate is built on advanced methodologies and industry knowledge to properly represent complex assets that don’t lend themselves to general or mass valuation practices.
          <ul className="service-bullets">
            <li>Two MAIs on staff</li>
            <li>100+ years of combined property tax consulting/appraisal experience</li>
            <li>Nationwide experience with the largest real estate owners and operators in America</li>
          </ul>
        </div>
      </div>
      <div className="service-item">
        <div className="service-title">Service</div>
        <div className="service-description">
          Property Tax representation is a relationship-driven business. We partner with our clients to provide high-quality service tailored to their specific needs.
          <ul className="service-bullets">
            <li>Always know who is working on your property</li>
            <li>Dedicated account manager and direct access to experts working on your property</li>
            <li>National scope, personal service</li>
          </ul>
        </div>
      </div>
      <div className="service-item">
        <div className="service-title">Results</div>
        <div className="service-description">
          We selected the word Solutions in our company name as we provide more than mere advice. We deliver results to our clients by mitigating their property tax expense and administrative burden.
          <ul className="service-bullets">
            <li>Identify and quantify obsolescence and intangibles to reduce property tax liability</li>
            <li>Effectively articulate and present complex methodologies</li>
            <li>Expert review of each property to identify all tax reduction opportunities</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Services;
