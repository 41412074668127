import React, { useRef, useEffect } from 'react';
import './Stats.css';
import { motion, useAnimation } from 'framer-motion';

const Stats = () => {
  const stats = [
    {
      icon: '<i class="fas fa-building"></i>',
      label: 'Property Tax Savings Achieved',
      value: 'Over $10 Million',
    },
    {
      icon: '<i class="fas fa-chart-line"></i>',
      label: 'Assets Under Management',
      value: 'Over $6 Billion',
    },
    {
      icon: '<i class="fas fa-trophy"></i>',
      label: 'Property Tax Experience',
      value: 'Over 200 Years Total',
    },
    { icon: '<i class="fas fa-user-tie"></i>', 
      label: 'Property Tax & Valuation Experts', 
      value: 'Team of MAIs & CMIs',},
  ];

  // Animation controls
  const controls = useAnimation();
  const statsRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            controls.start('visible');
          }
        });
      },
      { threshold: 0.3 } // Trigger when 30% of the section is visible
    );

    if (statsRef.current) {
      observer.observe(statsRef.current);
    }

    return () => {
      if (statsRef.current) {
        observer.unobserve(statsRef.current);
      }
    };
  }, [controls]);

  // Variants for individual stat items
  const itemVariants = {
    hidden: {
      x: -100,
      opacity: 0,
    },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
      },
    },
  };

  return (
    <div className="stats-container" ref={statsRef}>
      {stats.map((stat, index) => (
        <motion.div
          key={index}
          className="stat-item"
          initial="hidden"
          animate={controls}
          variants={itemVariants}
        >
          <motion.div
            className="stat-icon"
            dangerouslySetInnerHTML={{ __html: stat.icon }}
            initial="hidden"
            animate={controls}
            variants={{
              hidden: { scale: 0, opacity: 0 },
              visible: { scale: 1, opacity: 1, transition: { delay: index * 0.3, duration: 0.6 } }
            }}
          />
          <div className="stat-info">
            <div className="stat-label">{stat.label}:</div>
            <div className="stat-value">{stat.value}</div>
          </div>
        </motion.div>
      ))}
    </div>
  );
};

export default Stats;
