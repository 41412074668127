import React from 'react';
import './NewFooter.css';
import { Link } from 'react-router-dom';
import logoimg from '../images/slatenobackground.png';

function NewFooter() {
    return(
        <div className="newfooter-container" id="newfooter-container">
            <img
                src={logoimg}
                alt="Slate Property Tax Solutions Logo"
                className="logo-container-footer"
            />
            <small className="website-rights">Slate Property Tax Solutions © 2024</small>
            <Link
                className="social-icon-link twitter"
                to="https://www.linkedin.com/company/slatepts/"
                target="_blank"
                aria-label="LinkedIn"
             >
                <i className="fab fa-linkedin" />
            </Link>
        </div>
    );
}
export default NewFooter;