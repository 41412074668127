import React from 'react';
import './Bio.css';
import { Link } from 'react-router-dom';

const Bio = ({ name, role, bio, education, photo, linkedin, email }) => {
    const paragraphs = bio.split('\n').filter(paragraph => paragraph.trim() !== '');

    return (
        <div className="profile-outer-container">
            <div className="profile-container">
                <div className="profile-image-container">
                    <img src={photo} alt={`${name}'s photo`} className="profile-image" />
                </div>
                <div className="profile-content">
                    <h3 className="profile-role">{role}</h3>
                    <h2 className="profile-name">
                        {name}
                        {linkedin && (
                            <Link
                                className='social-icon-link linkedin'
                                to={linkedin}
                                target='_blank'
                                aria-label='LinkedIn'
                            >
                                <i className='fab fa-linkedin' />
                            </Link>
                        )}
                    </h2>
                    {paragraphs.map((paragraph, index) => (
                        <p key={index} className="profile-bio">{paragraph}</p>
                    ))}
                    <p className="profile-education">{education}</p>
                    <p className="profile-email">
                        Email: <a href={`mailto:${email}`} className="email-link">{email}</a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Bio;
