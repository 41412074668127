import React from 'react';
import '../../App.css';
import HeroSection from '../homepage/HeroSection'; // Assume this is critical and needs to load immediately
import AboutUsV2 from '../homepage/AboutUsV2'; // Assume this is critical and needs to load immediately
import Stats from '../homepage/Stats';
import Cards from '../homepage/Cards';
import Footer from '../homepage/Footer';
import TaxSavingsCarousel from '../homepage/TaxSavingsCarousel';
// const Stats = React.lazy(() => import('../homepage/Stats'));
// const Cards = React.lazy(() => import('../homepage/Cards'));
// const TaxSavingsCarousel = React.eager(() => import('../homepage/TaxSavingsCarousel'));
// const Footer = React.lazy(() => import('../homepage/Footer'));

function Home() {
  return (
    <>
      <HeroSection />
      <AboutUsV2 />
      <Stats/>
      <Cards/>
      <TaxSavingsCarousel/>
      <Footer/>
      {/* <Suspense fallback={<div>Loading stats...</div>}>
        <Stats />
      </Suspense>
      <Suspense fallback={<div>Loading cards...</div>}>
        <Cards />
      </Suspense>
      <Suspense fallback={<div>Loading carousel...</div>}>
        <TaxSavingsCarousel />
      </Suspense>
      <Suspense fallback={<div>Loading carousel...</div>}>
        <Footer />
      </Suspense> */}
    </>
  );
}

export default Home;