import React, { Suspense } from 'react';
import './TaxSavingsCarousel.css';
import assessmentsimg from '../../images/services/assessment analysis.jpg';
import appealsimg from '../../images/services/Appeals 1.jpg';
import budgetsimg from '../../images/services/budgets and insights 2.jpg';
import dueimg from '../../images/services/due diligence image FAV.jpg';
import personalpropertyimg from '../../images/services/Personal Property.jpg';
import taxbillimg from '../../images/services/Tax Bills Transmittals.jpg';

const TaxSavingsCarousel = () => {
  const carouselItems = [
    {
      src: assessmentsimg,
      alt: 'Assessment Analysis',
      link: '/assessment-details',
      title: 'Assessment Analysis',
      description:
        'Identify all opportunities for property tax savings to optimize the value of your portfolio.',
    },
    {
      src: appealsimg,
      alt: 'Appeals',
      link: '/appeals-details',
      title: 'Appeals',
      description:
        'Maximize potential property tax savings to increase the net operating income across your portfolio.',
    },
    {
      src: dueimg,
      alt: 'Due Diligence Property Tax Estimates',
      link: '/due-diligence-details',
      title: 'Due Diligence Property Tax Estimates',
      description:
        'Grow confidently with key insights into your new asset’s largest annual operating expense.',
    },
    {
      src: taxbillimg,
      alt: 'Tax Bill Transmittals',
      link: '/tax-bill-details',
      title: 'Tax Bill Transmittals',
      description:
        'Reduce your administrative burden and mitigate the risk of incorrect or delinquent payments.',
    },
    {
      src: budgetsimg,
      alt: 'Budgets and Insights',
      link: '/budgets-details',
      title: 'Budgets and Insights',
      description:
        'Minimize property tax surprises for tenants, operators and accounting departments.',
    },
    {
      src: personalpropertyimg,
      alt: 'Personal Property Filings',
      link: '/personal-property-details',
      title: 'Personal Property Filings',
      description:
        'Ensure compliance with state-specific rules and regulations while identifying potential savings.',
    },
  ];

  return (
    <div className="TaxSavingsCarousel-section">
      <div className="title-container">
        <div className="testimonials-separator"></div>
        <h1 className="TaxSavingsCarousel-header">Services</h1>
      </div>
      {carouselItems.map((item, index) => (
        <div className="TaxSavingsCarousel-card" key={index}>
          <a href={item.link}>
            <div className="image-container">
              <Suspense fallback={<div className="loading-placeholder"></div>}>
              <img
                src={item.src}
                alt={item.alt}
                className="TaxSavingsCarousel-image"
              />
              </Suspense>
            </div>
          </a>
          <a href={item.link} className="TaxSavingsCarousel-title">
            {item.title}
          </a>
          <p className="TaxSavingsCarousel-description">{item.description}</p>
        </div>
      ))}
      <div className="testimonials-separator"></div>
    </div>
  );
};

export default TaxSavingsCarousel;
