import React from 'react'
import './CardItem.css'

function CardItem(props) {
    const mailtoLink = `mailto:${props.email}`;
    return (
    <>
        <li className='cards__item'>
            <div className='cards__item__link'>
                <figure className="cards__item__pic-wrap" data-category={props.label} 
                onClick={props.onClick}
                >
                    <img src={props.src} alt="Bio" className="cards__item__img" />
                    <a href={props.route} class="overlay">View Bio</a>

                </figure>
                <div className="cards__item__info">
                    <h5 className="cards__item__text">{props.text}</h5>
                    <h6 className='cards__item__email'>
                        <a href={mailtoLink} className='cards__item__email_text'>
                            {props.email}
                        </a>
                    </h6>
                </div>
            </div>
        </li>
    </>
  )
}

export default CardItem